import React from 'react';
import LorySlider from './lory-slider';

import HomeCategoryImg from '../../assets/images/categories/home.jpg';
import CommercialCategoryImg from '../../assets/images/categories/commercial.jpg';
import BatteryStorageCategoryImg from '../../assets/images/categories/battery-storage.png';

const HomepageCarousel = () => (
  <LorySlider mobileOnly classNames="mobile-carousel homepage-carousel">
    <li className="js_slide homepage-carousel__product">
      <a href="/categories/home-ev-charging">
        <div className="homepage-carousel__image-wrapper">
          <img
            className="homepage-carousel__product-image"
            src={HomeCategoryImg}
            alt=""
          />
        </div>

        <div>
          <p className="homepage-carousel__product-title">Home Charging</p>
          <p className="homepage-carousel__product-desc">
            Browse through our Home Charging range to identify a cost-effective
            domestic solution for your vehicle.
          </p>
        </div>
      </a>
    </li>

    <li className="js_slide homepage-carousel__product">
      <a href="/categories/commercial-ev-charging">
        <div className="homepage-carousel__image-wrapper">
          <img
            className="homepage-carousel__product-image"
            src={CommercialCategoryImg}
            alt=""
          />
        </div>

        <div>
          <p className="homepage-carousel__product-title">
            Commercial Charging
          </p>
          <p className="homepage-carousel__product-desc">
            Charging provision for revenue generation or fleet management is now
            more common across commercial locations.
          </p>
        </div>
      </a>
    </li>

    <li className="js_slide homepage-carousel__product">
      <div className="homepage-carousel__image-wrapper">
        <img
          className="homepage-carousel__product-image"
          src={BatteryStorageCategoryImg}
          alt=""
        />
      </div>
      <div>
        <p className="homepage-carousel__product-title">Battery Storage</p>
        <p className="homepage-carousel__product-desc">
          Battery storage integrates with PV and EV charging for efficient power
          management.
        </p>
      </div>

      <div className="homepage-carousel__product-links fl jc-se mt-30">
        <a
          href="categories/battery-storage-home-battery-storage"
          type="button"
          className="product-links__link">
          Residential
        </a>
        <a
          href="categories/battery-storage-commercial-battery-storage"
          type="button"
          className="product-links__link">
          Commercial
        </a>
      </div>
    </li>
  </LorySlider>
);
export default HomepageCarousel;
