/* eslint-disable no-loop-func */
import React, { useLayoutEffect } from 'react';
import { lory } from 'lory.js';
import AccordionIcon from '../../assets/images/icons/accordion.svg';

const LorySlider = ({ children, mobileOnly, classNames, imageThumbs }) => {
  const sliderRef = React.createRef();
  const dotNavigation = <div className="carousel-nav-dots" />;

  let imageThumbNav;
  let lorySlider;

  if (imageThumbs) {
    imageThumbNav = (
      <ul className="carousel-thumbnail-wrapper">
        {imageThumbs.map((image, key) => (
          <li key={key} className="image-thumb">
            <button type="button">
              <img
                src={image.content.url}
                width="50"
                height="50"
                alt={`View image ${key}`}
              />
            </button>
          </li>
        ))}
      </ul>
    );
  }

  const handleEvents = (e) => {
    const { type } = e;
    const slider = document.querySelector('.slider');
    const dotCount = slider.querySelectorAll('.js_slide').length;
    const dotContainer = slider.querySelector('.carousel-nav-dots');
    const dotListItem = document.createElement('button');
    const thumbnailWrapper = slider.querySelector(
      '.carousel-thumbnail-wrapper',
    );

    if (type === 'before.lory.init') {
      for (let i = 0, len = dotCount; i < len; i++) {
        const clone = dotListItem.cloneNode();
        clone.classList.add('menu-dot');
        dotContainer.appendChild(clone);
      }
      dotContainer.childNodes[0].classList.add('active');

      // On init - set selected to the first thumbnail
      if (thumbnailWrapper !== null) {
        thumbnailWrapper
          .querySelectorAll('.image-thumb')[0]
          .classList.add('selected');
      }
    }

    if (type === 'after.lory.init') {
      if (thumbnailWrapper) {
        const imageThumbnails = thumbnailWrapper.querySelectorAll(
          '.image-thumb',
        );

        // Click handler for the image thumbnails to move to relevant slide
        for (let i = 0; i < imageThumbnails.length; i++) {
          const thumb = imageThumbnails[i];
          // eslint-disable-next-line no-loop-func
          thumb.addEventListener('click', (e) => {
            if (i !== lorySlider.returnIndex()) {
              lorySlider.slideTo(i);
            }
          });
        }
      }

      for (let i = 0, len = dotCount; i < len; i++) {
        // eslint-disable-next-line no-loop-func
        if (dotContainer.childNodes[i]) {
          dotContainer.childNodes[i].addEventListener('click', (ev) => {
            lorySlider.slideTo(
              Array.prototype.indexOf.call(dotContainer.childNodes, ev.target),
            );
          });
        }
      }
    }

    if (type === 'after.lory.slide') {
      if (thumbnailWrapper) {
        const imageThumbnails = thumbnailWrapper.querySelectorAll(
          '.image-thumb',
        );

        // After slide we remove selected classes and set the selected class to the selected image thumbnail
        for (let i = 0; i < imageThumbnails.length; i++) {
          const thumb = imageThumbnails[i];
          thumb.classList.remove('selected');
        }
        imageThumbnails[lorySlider.returnIndex()].classList.add('selected');
      }

      for (let i = 0, len = dotContainer.childNodes.length; i < len; i++) {
        dotContainer.childNodes[i].classList.remove('active');
      }
      dotContainer.childNodes[e.detail.currentSlide - 1].classList.add(
        'active',
      );
    }

    if (type === 'on.lory.resize') {
      if (thumbnailWrapper) {
        const imageThumbnails = thumbnailWrapper.querySelectorAll(
          '.image-thumb',
        );

        // On resize - Set selected to the first thumbnail
        for (let i = 0; i < imageThumbnails.length; i++) {
          const thumb = imageThumbnails[i];
          thumb.classList.remove('selected');
        }

        imageThumbnails[lorySlider.returnIndex()].classList.add('selected');
      }

      for (let i = 0, len = dotContainer.childNodes.length; i < len; i++) {
        dotContainer.childNodes[i].classList.remove('active');
      }
      dotContainer.childNodes[0].classList.add('active');
    }
  };

  const handlePrev = () => {
    lorySlider.prev();
  };

  const handleNext = () => {
    lorySlider.next();
  };

  const destroyLory = () => {
    const dotContainer = document.querySelector('.carousel-nav-dots');
    if (lorySlider) {
      lorySlider.destroy();
      lorySlider = undefined;
      while (dotContainer.firstChild) {
        dotContainer.removeChild(dotContainer.lastChild);
      }
    }
  };

  useLayoutEffect(() => {
    function updateSize() {
      if (window.innerWidth >= 768 && mobileOnly) {
        destroyLory();
      } else if (!lorySlider) {
        sliderRef.current.addEventListener('before.lory.init', handleEvents);
        sliderRef.current.addEventListener('after.lory.init', handleEvents);
        sliderRef.current.addEventListener('after.lory.slide', handleEvents);
        sliderRef.current.addEventListener('on.lory.resize', handleEvents);
        lorySlider = lory(sliderRef.current, {
          infinite: 1,
        });
      }
    }

    window.addEventListener('resize', updateSize);
    updateSize();
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  return (
    <>
      <div ref={sliderRef} className={`slider js_slider ${classNames}`}>
        <div className="frame js_frame">
          <ul className="slides js_slides">{children}</ul>
        </div>

        {imageThumbNav}

        <div className="fl ai-c jc-sb carousel-controls">
          <button
            onClick={handlePrev}
            className="carousel-controls__previous"
            type="button">
            <img src={AccordionIcon} alt="" />
          </button>
          {dotNavigation}
          <button
            onClick={handleNext}
            className="carousel-controls__next"
            type="button">
            <img src={AccordionIcon} alt="" />
          </button>
        </div>
      </div>
    </>
  );
};
export default LorySlider;
